@import '../../common/scss/mixin.scss';

.modal {
  &-content {
    position: relative;
    padding: 3.75rem 2.5rem;
    .close_btn {
      position: absolute;
      top: 20px;
      right: 20px;
      outline: none;
      border: none;
      background: transparent;
    }
    .icon-buoumaotubiao20 {
      font-size: 20px;
      font-weight: 400;
      cursor: pointer;
      color: #666;
      &:hover {
        color: hsla(0,0%,40.8%,.6);
      }
    }
  }

  &-header {
    justify-content: center;
  }

  &-title {
    font-size: 48px;
    color: #333333;
  }

  &-body {
    font-size: 18px;
    text-align: center;
  }

  &-footer {
    justify-content: center;
  }
}


.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}