.navbar-light {
  .navbar-nav {
    .nav-link {
      color: #333333;
      font-weight: bold;

      &:focus,
      &:hover {
        outline: none;
        color: #2661E9;
      }

      &:after {
        margin-left: 6px;
        vertical-align: 2px;
        border-top: 6px solid;
        border-right: 4.5px solid transparent;
        border-left: 4.5px solid transparent;
      }
    }
  }
}

.navbar-toggler {
  &:focus,
  &:hover {
    outline: none;
    border-color: #f7931e;
  }
}

// @media (max-width: 1415px) {
//   .navbar-expand-lg .navbar-nav .nav-link {
//     padding-right: 0.5rem;
//     padding-left: 0.5rem;
//   }
// }
// @media (max-width: 1336px) {
//   .navbar-expand-lg .navbar-nav .nav-link {
//     padding-right: 0.2rem;
//     padding-left: 0.2rem;
//   }
// }
