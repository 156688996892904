// Brand Colors
$blue: #2661E9;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #F5333D;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray-100: #f9f9f9;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #333;
$gray-900: #212529;
$black: #000;
$grays: (100: $gray-100, 200: $gray-200, 300: $gray-300, 400: $gray-400, 500: $gray-500, 600: $gray-600, 700: $gray-700, 800: $gray-800, 900: $gray-900);
$theme-colors: (primary: $blue, secondary: $gray-600, success: $green, info: $cyan, warning: $yellow, danger: $red, light: $gray-100, dark: $gray-800);
$colors: (blue: $blue, indigo: $indigo, purple: $purple, pink: $pink, red: $red, orange: $orange, yellow: $yellow, green: $green, teal: $teal, cyan: $cyan, white: $white, gray: $gray-600,gray-dark: $gray-800);
$theme-color-interval: 8%;
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1200px);

// Grid
$grid-gutter-width: 20px;

// Body
$body-bg: #F4F4F4;
$body-color: #666;

// Components
$border-radius: .25rem;
$component-active-color: #666;
$component-active-bg: #EFF2F5;

// Fonts
$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .875;

// Buttons
$input-btn-padding-y: .4375rem;
$input-btn-padding-x: 1.25rem;
$btn-border-radius-lg: $border-radius;
$input-btn-padding-y-sm: .21875rem;
$input-btn-padding-x-sm: .875rem;
$btn-font-size-lg: 1.125rem;
$btn-padding-y-lg: .96875rem;
$btn-padding-x-lg: 2rem;

// Breadcrumbs
$breadcrumb-padding-y: 1.25rem;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: .125rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: #666;
$breadcrumb-active-color: #666;

// Forms
$input-btn-padding-y-lg: .90625rem;
$input-bg: $gray-100;
$input-disabled-bg: $input-bg;
$input-border-color: $input-bg;
$input-color: #666;
$input-focus-bg: $input-bg;
$input-focus-border-color: $input-bg;
$input-focus-box-shadow: none;
$input-placeholder-color: #666;
$input-btn-font-size-lg: $font-size-sm;
$input-btn-padding-x-lg: 1.25rem;
$input-border-radius-lg: $border-radius;

// Dropdown
$dropdown-border-width: 0;
$dropdown-font-size: $font-size-sm;
$dropdown-border-radius: 0;
$dropdown-min-width: 8.75rem;
$dropdown-padding-y: 0;
$dropdown-spacer: 0;
$dropdown-link-hover-color: $blue;
$dropdown-link-hover-bg: #EFF2F5;
$dropdown-item-padding-x: 1.25rem;

// Navbar
$nav-link-padding-y: .75rem;
$navbar-padding-y: .375rem;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: 1.25rem;

// Modals
$modal-inner-padding: 1.25rem;
$modal-content-border-width: 0;
$modal-backdrop-opacity: 0.6;
$modal-header-padding: 1.25rem;
$modal-md: 700px;
$modal-content-border-radius: $border-radius;
