.table {
  margin-bottom: 0;

  th,
  td {
    border: none;
  }

  tr {
    border-bottom: 1px solid #dee2e6;
  }

  th {
    padding-top: 50px;
    padding-bottom: 25px;
  }

  td {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}